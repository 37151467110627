import { useRouter } from 'next/router';
import React from 'react';

import { outboundLinkAction } from '@/helpers/gtmActions/gtmAcions';

import { SiteContext } from '../../context';
import { InternalExternalLink } from '../Link/InternalExternalLink';
import { IconLoader } from '../images/IconLoader';

export const SocialLinks = () => {
  const router = useRouter();
  const { config } = React.useContext(SiteContext);
  const socials = Object.entries(config.socialLinks).map(([key, value]) => ({
    type: key,
    href: value,
  }));

  if (!socials?.length) return null;

  const handleSocialsClick = (e) => {
    const url = e.currentTarget.getAttribute('href');
    const path = router.asPath;
    outboundLinkAction(url, path);
  };

  return (
    <ul className="flex flex-wrap gap-2">
      {socials?.map(({ type, href }) => (
        <li key={type}>
          <InternalExternalLink
            href={href}
            target="_blank"
            rel="noreferrer noopener"
            onClick={handleSocialsClick}
            aria-label={type}
            title={type}
          >
            <IconLoader src={`/icons/social/${type}.svg`} className="w-8 h-8" />
          </InternalExternalLink>
        </li>
      ))}
    </ul>
  );
};
