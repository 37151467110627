import TagManager from 'react-gtm-module';

const getFileName = (url) => url.split('/').pop();

export const downloadsAction = (type, page, downloadLink) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track('Lead');
    });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Downloads',
      category: 'Downloads',
      action: `${type}: ${page}`,
      label: getFileName(downloadLink),
      value: '',
    },
  });
};

export const outboundLinkAction = (url, pageUrl) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Outbound links',
      category: 'Outbound links',
      action: `Clicked on ${url}`,
      label: `From page ${pageUrl}`,
      value: '',
    },
  });
};

export const videoAction = (type, progress, pageUrl, videoName, duration) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Video interactions',
      category: `Videos - ${type}`,
      action: `Play ${progress}% watched`,
      label: `${pageUrl}: ${videoName}`,
      value: duration,
    },
  });
};

export const scheduleAction = (type, location, pageUrl) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track('Schedule-Viewing');
    });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Schedule Viewing',
      category: `Schedule viewing - ${type}`,
      action: `Clicked on ${location}`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const registerInterestAction = (location, pageUrl) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track('Contact');
    });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Register Interest',
      category: ' Register interest',
      action: `Clicked on ${location}`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const requestCallbackAction = (location, pageUrl) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track('Request Callback');
    });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Request a callback',
      category: 'Request a call back',
      action: `Clicked on ${location}`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const virtualTourAction = (type, location, pageUrl) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track('Virtual tour');
    });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Virtual tour',
      category: `Virtual tour - ${type}`,
      action: `Started virtual tour on ${location}`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const registerInterestFlowAction = (step, pageUrl) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Register Interest (flow progress)',
      category: 'Register interest',
      action: `Viewed ${step}`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const submittedRegisterInterestFlowAction = (development, pageUrl) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Register Interest Submitted',
      category: `${development}`,
      action: `Register Interest Submitted`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};

export const subscribeAction = (type, category, pageUrl) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Subscribe to newsletter',
      category: `Subscribe to newsletter - ${type}`,
      action: ` Subscribed to ${category} news`,
      label: `${pageUrl}`,
      value: '',
    },
  });
};
