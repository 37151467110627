import * as RadixDialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import { InternalExternalLink } from '../Link/InternalExternalLink';
import { IconLoader } from '../images/IconLoader';
import { SocialLinks } from '../social/SocialLinks';

export const MobileNav = ({
  menu,
  cta,
  quicklinks,
  contact,
  open,
  onOpenChange,
}) => {
  return (
    <div className={cx('radix-dialog', { ['hidden']: !open })}>
      <RadixDialog.Root onOpenChange={onOpenChange} open={open}>
        <RadixDialog.Overlay className="relative z-[9999]">
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-40 w-screen h-screen"
            animate={{ opacity: 0.5 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'circOut', duration: 2 }}
          />
        </RadixDialog.Overlay>
        <RadixDialog.Content className="z-[9999] fixed top-0 right-0 w-[85vw] max-w-xs h-screen">
          <motion.div
            animate={{ x: open ? 0 : '100vw' }}
            initial={{ x: '100vw' }}
            exit={{ y: 0 }}
            transition={{ ease: [0.215, 0.61, 0.355, 1.0], duration: 0.25 }}
            className="h-full"
          >
            <motion.div
              className="h-full"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'circOut' }}
            >
              <RadixDialog.Title className="sr-only">Navigation</RadixDialog.Title>
              <RadixDialog.Close className="absolute top-3 right-3">
                <span className="block p-2 text-white bg-blue-navy border border-white/10 rounded-sm">
                  <IconLoader className="w-6 h-6" src="/icons/close.svg" />
                </span>
              </RadixDialog.Close>

              <div
                className="h-full overflow-y-auto overflow-scrolling-touch bg-blue-navy select-none shadow-2xl text-base text-blue-navy"
                onClick={() => onOpenChange(false)}
              >
                <ul className="p-4 bg-gray-spring text-sm flex flex-col gap-2">
                  <li className="mb-2">
                    <InternalExternalLink href="/" className="inline-block">
                      <IconLoader
                        className="w-[94px] h-[36px]"
                        src="/logo-mobile.svg"
                      />
                    </InternalExternalLink>
                  </li>
                  {contact?.map(({ label, current, href }) => (
                    <li key={label} className="">
                      <InternalExternalLink
                        href={href}
                        className={cx(
                          'inline-flex gap-2 items-center hover:underline',
                          {
                            ['underline']: current,
                          },
                        )}
                      >
                        {label}
                      </InternalExternalLink>
                    </li>
                  ))}
                </ul>

                <div className="p-4 text-white">
                  {/* quicklinks + cta */}
                  {(Boolean(quicklinks?.length) || Boolean(cta?.length)) && (
                    <ul className="my-1 flex flex-wrap gap-2">
                      {[...quicklinks, ...cta]
                        ?.sort(({ label }) =>
                          label.toLowerCase().indexOf('corporate') > -1 ||
                          label.toLowerCase().indexOf('customer') > -1
                            ? -1
                            : 1,
                        )
                        .map(({ label, current, internal, external, params }) => {
                          const isScopeSwitch =
                            label.toLowerCase().indexOf('corporate') > -1 ||
                            label.toLowerCase().indexOf('customer') > -1;

                          return (
                            <li key={label} className="">
                              <InternalExternalLink
                                id={internal?._ref}
                                href={external}
                                params={params}
                                className={cx(
                                  'flex gap-1 items-center hover:underline border border-white/50 rounded-sm py-1 px-2 text-sm',
                                  {
                                    ['bg-gray-spring px-2 py-1 rounded-sm transition-colors hover:bg-gray-china text-blue-navy']:
                                      isScopeSwitch,
                                    ['underline']: current,
                                  },
                                )}
                              >
                                {label}
                                <IconLoader
                                  className="w-2 h-2 flex-shrink-0"
                                  src="/icons/chevron.svg"
                                />
                              </InternalExternalLink>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>

                {/* menu */}
                {Boolean(menu?.length) && (
                  <ul className="border-t border-t-white/10 divide-y divide-white/10 text-gray-spring">
                    {menu?.map(
                      ({ label, internal, external, children, current, params }) => (
                        <li key={label} className="group -mt-px">
                          <InternalExternalLink
                            id={internal?._ref}
                            href={external}
                            params={params}
                            className={cx(
                              'hover:underline p-4 inline-flex gap-2 items-start ',
                              {
                                ['underline']: current,
                              },
                            )}
                          >
                            <IconLoader
                              className="w-2 h-2 flex-shrink-0 translate-y-full"
                              src="/icons/chevron.svg"
                            />
                            {label}
                          </InternalExternalLink>

                          {/* subnav */}
                          {Boolean(children?.length) && (
                            <ul className="mb-3 flex flex-col gap-2">
                              {children?.map(
                                ({ label, current, internal, external, params }) => (
                                  <li key={label}>
                                    <InternalExternalLink
                                      id={internal?._ref}
                                      href={external}
                                      params={params}
                                      className={cx(
                                        'inline-flex gap-2 items-start hover:underline px-8',
                                        {
                                          ['underline']: current,
                                        },
                                      )}
                                    >
                                      <IconLoader
                                        className="w-2 h-2 flex-shrink-0 translate-y-full"
                                        src="/icons/chevron.svg"
                                      />
                                      {label}
                                    </InternalExternalLink>
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </li>
                      ),
                    )}
                  </ul>
                )}

                <div className="text-gray-spring p-4">
                  <SocialLinks />
                </div>

                <div className="h-16 bg-red-white" />
              </div>
            </motion.div>
          </motion.div>
        </RadixDialog.Content>
      </RadixDialog.Root>
    </div>
  );
};

export default MobileNav;
